import { initializeApp } from "firebase/app";
import { getAnalytics } from "@firebase/analytics";
import { getFirestore } from "firebase/firestore";
import SunImg from "./bsun.jpg";
import { getStorage, ref } from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as firebaseui from "firebaseui";
import "firebase/storage";
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { userVerification } from "../functions/userVerification";
import { GoogleAuthProvider } from "firebase/auth";
import { getApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const provider = new GoogleAuthProvider();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);

const functions = getFunctions(app, "us-central1");
const auth = getAuth();
setPersistence(auth, browserSessionPersistence);

const addAdminRole = httpsCallable(functions, "addAdminRole");

export const logInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);

    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.log(user.email);
    const isAdmin = await addAdminRole({ email: user.email });
    console.log(isAdmin);
  } catch (err) {
    console.error(err);
    const errCode = err.code;
    const errMessage = err.message;
    // The email of the user's account used.
    const email = err.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(err);
  }
};
