import { useState } from "react";
import Button from "@mui/material/Button";
import { Link  } from 'react-router-dom';

const NavButton = (props) => {

  const [btnColor, setBtnColor] = useState(false);
  const handleClick = () => {
    // console.log(props.closeModal())
    console.log('click')
  };
  const style = {
    // backgroundColor: btnColor == true ? "#d8d8d8" : null,
    display:'flex',
    alignItems: 'center',
    color:  "#d8d8d8",
    fontWeight: 500,
    fontFamily: "Bebas Neue Pro Regular",
    fontSize: '18px',
    height: "35px",
    borderRadius: "50px",
    fontWeight: "bold",
    padding:'20px',
    '&:hover':{
      backgroundColor:'#c22350'
    },
    '&:focus':{
      backgroundColor:'#d8d8d8',
      color:'#c22350'
    },

  }

  return (
    <Link style={{border:'0px',textDecoration:'none'}}  to={props.url} >
    <Button
      onClick={props.closeModal}
      fullWidth={true}
      key={props.index}
      // disableTouchRipple="true"
      // onFocusVisible="false"
      sx={props.styling ==null ? style : props.styling}
    >

      <p>{props.text}</p>
      

    </Button>
</Link >

  );
};

export default NavButton;
