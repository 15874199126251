import * as React from "react";
import Logo from "../../img/Logo.gif";
import NavButton from "./navbutton.jsx";
import SearchButton from "./searchButton.jsx"
import SocialMedia from "../socialMedia.jsx";

import Grid from '@mui/material/Grid';
import { Link  } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import UserMenu from '../userMenu'
import {logInWithGoogle} from '../../firebase/app'
const DesktopBar = (props) =>{

const styles = {
  logoSection:{
    display: "flex", 
    flexWrap:'wrap', 
    justifyContent:'center', 
    alignItems: "center", 
    backgroundColor:'#d8d8d8', 
    borderRadius:'0px 35px 35px 0px' 
  },
  buttonSection:{
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingLeft: '2vw',
    paddingRight: '2vw',
  }
}


    return(

        <>
        <Grid item xs={3} lg={2} style={{...styles.logoSection, cursor:'pointer'}}>
         <Link style={{border:'0px',textDecoration:'none'}}  to={'/'} >

          <img src={Logo} alt="" srcSet=""  style={{width:'70%'}}/>
         </Link>

        </Grid>
        <Grid item md={1} lg={3} style={{ display: "flex", alignItems: "center", marginLeft: '25px'}}>
          <SearchButton/>
        </Grid>
        
        <Grid item md={2} lg={5} style={styles.buttonSection}>
          {props.buttonsText.map((button, index) => {
            return <NavButton key={index} url={button.link} text={button.name} index={index} /> 
          })}
        </Grid>
           
        <Grid item xs={1}  style={{ display: "flex", alignItems: "center"}}>

        <SocialMedia/>
        </Grid>
        <Grid item xs={1}  style={{ display: "flex", alignItems: "center"}}>
        {props.user === null ? 
            <IconButton style={{backgroundColor:'white', marginLeft:'25px', color:'black'}} onClick={()=>{logInWithGoogle()}}>
              <PersonIcon style={{fontSize:'30px'}}/>
            </IconButton>
          :
          <UserMenu user={props.user} onLogout={props.onLogout}/>
        }
        </Grid>
        </>
    )
}

export default DesktopBar