import {db} from '../firebase/app'
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

export const getSliderImgs = async (_collection) =>{
    // const mapNoticias = new Map();
    const imgs = []
    const querySnapshot = await getDocs(collection(db, _collection));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
    //   mapNoticias.set(doc.id,doc.data())
    let data = doc.data()
    data.id = doc.id
    imgs.push(data)
    });
    return imgs
}

export const changePostImageVisibility = async (posts,_collection) =>{
  for (const id in posts) {
    if (Object.hasOwnProperty.call(posts, id)) {
      const element = posts[id];
      await updateDoc(doc(db,_collection,id), element);
    }
  }
  // console.log(posts)
  // for (const id of posts) {
  //   console.log(id, posts[id])
    // await updateDoc(doc(db,'noticias',id), posts[id]);
  // }
// const img = doc(db, "noticias", id);

// // Set the "capital" field of the city 'DC'
// await updateDoc(img, {
//   showAtHomeCarousel: shouldDisplay
// });
}
