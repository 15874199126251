
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import {uploadFrontSlider} from '../../functions/imgUploadFrontSlider'
import PostPreview from './postPreview'
import {getAdmins,insertDocument,deleteDocument} from '../../functions/getAdmins'
import {useState, useEffect, useRef } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import {changePostImageVisibility} from '../../functions/getSliderImgs'
import Input from '@mui/material/Input';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  height:'50%',
  bgcolor: '#d8d8d8',
  borderRadius:'35px',
  boxShadow: 24,
  p: 4,
  zIndex: 500
};

const DeleteButton = (props) =>{

  const handleClick = () =>{
      props.handleDelete(props.admin)
  }
  return(
    <Button style={{width:'100%',height:'100%',padding:0,}} onClick={handleClick}>
    <DeleteIcon style={{ fontSize:'24px', color:'rgb(221, 46, 96)'}}/>
    </Button>
  )
}
const AdminManager = (props) => {
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [admins,setAdmins] = useState(null)
  const [newsToUpdate,setNewsToUpdate] = useState([])
  const [newAdmin,setNewAdmin] = useState(false)
  const [addAdmin,setAddAdmin] = useState('')

  const addChanges = (newChange) =>{

    setNewsToUpdate(
      newsToUpdate =>{
        return{
         ...newsToUpdate, [Object.keys(newChange)[0]]: Object.values(newChange)[0]
        }
      }
    )

  }

  
  const fetchAdmins = async()=>{
    const fetchedAdmins = await getAdmins()
    setAdmins(fetchedAdmins)
}
  useEffect(() =>{
    fetchAdmins()
  },[])

  const handleNewAdmin = ()=>{
    console.log(addAdmin)
    setAdmins([...admins,addAdmin])
    insertDocument(addAdmin,'admins')
    setAddAdmin('')
  }

  const handleDelete = (adminToDelete) =>{
    setAdmins(admins.filter((admin)=>admin!==adminToDelete))
    deleteDocument(adminToDelete,'admins')

  }
  return (
    <div>
      <Button style={{color:'rgb(221, 46, 96)'}} onClick={handleOpen}>Administrar usuarios</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        autoFocus={false}
      >
        <Box sx={style}>
            <Grid container>
                <Grid item xs={12}>
                <h2 style={{fontFamily: "Bebas Neue Pro Regular", textAlign: 'center',}}>ADMINISTRADORES</h2>
                
                </Grid>


                <Grid item xs={12} style={{ display:'flex',justifyContent: 'center'}} >
                
                <div>
                        
                {admins!=null && admins.map((admin,index)=>{
                    return <div key={index} style={{display:'flex', padding:'5px', width:'100%'}}>
                    
                          <span style={{height: '100%', backgroundColor:'rgb(221, 46, 96)',border: '1px solid rgb(221, 46, 96)', color:'#d8d8d8', display:'flex',paddingLeft:'3px', paddingBottom:'1px', paddingRight:'5px', borderRadius: '35px 0px 0px 35px'}}>
                            <PersonIcon/>
                            ADMIN
                            </span> 
                            <span style={{display:'flex',justifyContent: 'space-between', height: '100%', width: '100%',border: '1px solid rgb(221, 46, 96)',fontWeight:'bold', display:'inherit',paddingLeft:'5px', paddingRight:'5px',borderRadius: '0px 35px 35px 0px'}}>
                            {admin}                             
                            <span style={{borderLeft: '1px solid rgb(221, 46, 96)'}}>
                           <DeleteButton admin={admin} handleDelete={handleDelete}/>
                             </span> 
                            </span> 

                          </div>
                    })
                }

                    <span  style={{display: newAdmin ? 'block': 'none'}}>
                    <Input onKeyDown={(e)=>e.stopPropagation()} placeholder="Ingresar mail" value={addAdmin} autoFocus={true} onChange={(e)=>{setAddAdmin(e.target.value)}} />
                    <IconButton onClick={handleNewAdmin} aria-label="accept" style={{color:'green'}}>
                    <CheckCircleIcon />
                    </IconButton>
                    <IconButton onClick={() => setNewAdmin(!newAdmin)}  aria-label="cancel"  style={{color:'red'}}>
                    <CancelIcon />
                    </IconButton>
                    </span>
                    <Button style={{backgroundColor:'rgb(221, 46, 96)', display:newAdmin? 'none':'flex', color:'#d8d8d8', marginLeft:'15px'}}  onClick={() => setNewAdmin(!newAdmin)}><AddIcon/></Button>

                    
                </div>

                </Grid>

            </Grid>

        </Box>
      </Modal>
    </div>
  );
}





export default AdminManager