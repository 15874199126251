import * as React from 'react';

import Grid from "@mui/material/Grid";
import noticias from '../noticias.json'
import {Link} from 'react-router-dom'
import ImgCarousel from './carrousel'
import { useEffect, useState } from "react";
import Loading from './loading'
            
const calcDate = (seconds,nanoseconds) =>{
    return new Date(seconds * 1000 + nanoseconds/1000000)
}
const Post = (props) =>{
    
   
    const [notices,setNoticias] = useState([])
    const [isLoaded,setIsLoaded] = useState(false)
    useEffect(() => {
        setNoticias(props.noticias)
        // console.log(props.noticias)
    }, [props.noticias]);

    useEffect(() => {
    }, [props.size]);
    
    useEffect(() => {
        setIsLoaded(true)
    }, [notices]);


    return (

          <Grid container spacing={2} style={{marginTop:'10vh',display:'flex',}} >
            { 
             notices.length ?
             notices.map((noticia,index) =>
                {
                    let isNew = calcDate(noticia.date.seconds, noticia.date.nanoseconds) > (new Date() - 24*60*60*1000)
                    return props.size<700 ?  <Mobile noticia={noticia} key={index} isNew={isNew}/> : <DesktopTablet noticia={noticia} key={index} isNew={isNew}/> 
                }
            ):<Loading/>
            }
            
            </Grid> 

      );

}

const DesktopTablet = ({noticia,isNew}) =>{

    
    const styles={
        title:{
            fontSize:'32px', lineHeight:'36px', margin:'0px',textAlign:'left', color:'#dd2e60', fontWeight:'bold', marginTop: '15px', 
        },
        description:{
            lineHeight:'26px',fontSize:'20px', textAlign:'left', margin:'0px',letterSpacing:'0.5px', fontWeight:'bold',marginTop: '10px'
        },
        imgholder:{
            position:'relative',width:'100%', height:'auto',display:'flex'
          }
    }

    return (
        <Grid item sm={12}>
            <Link to={'/noticia/' + noticia.title} style={{textDecoration: 'none',color:'black'}}>
            <div style={{display: 'flex', fontFamily: "Poppins, sans-serif",lineHeight:'2em', position:'relative'}}>
                <div>
                    <div style={{width:'100%', backgroundSize:'cover', width:"300px", height:"300px", backgroundPosition:'center'}}>
                    <img src={noticia.img_sm} style={{boxShadow:'2px 2px 15px 0px rgba(0,0,0,0.67)', borderRadius:'15%', width:'100%', height:'100%', objectFit:'cover'}}alt="" />
                    </div>
                </div>
                <div style={{display: 'flex',flexDirection:'column',padding:'30px', textAlign:'left'}}>
                {isNew && <span style={{backgroundColor:'#dd2e60',width:'fit-content',padding:'3px', color:'white'}}><b>NUEVO</b></span>}
                    <p style={{position:'absolute', top:'-15px'}}>{calcDate(noticia.date.seconds, noticia.date.nanoseconds).toLocaleDateString()}</p>
                    <p style={{...styles.title, overflow: 'hidden',textOverflow: 'ellipsis', display: 'block',wordWrap: 'break-word',  maxHeight: '72px'}}>{(noticia.title).toUpperCase()} </p> 
                    <p style={{...styles.description, overflow: 'hidden',textOverflow: 'ellipsis', display: 'block',wordWrap: 'break-word',  maxHeight: '130px'}}>{noticia.text1}</p>
                </div>
            </div>
            <div style={{border:'2px solid black',marginBottom:'30px',marginTop:'45px',opacity: 0.3}}></div>

                {/* <Article title={noticia.title} description={noticia.subtitle} img={noticia.img_sm}/> */}
            </Link>
        </Grid>
    )
}

const Mobile = ({noticia,isNew}) =>{
    // console.log(noticia.date.seconds > (new Date() - 24*60*60*1000))
    // console.log(new Date(noticia.date.seconds * 1000 + noticia.date.nanoseconds/1000000) < (new Date() - 24*60*60*1000))
    // console.log(noticia)
    const styles={
        title:{
            fontSize:'32px', margin:'0px',textAlign:'left', color:'#d8d8d8', fontWeight:'bold', marginTop: '15px',textAlign:'center'
        },
        description:{
            fontSize:'20px', height: '3rem',textAlign:'left', margin:'0px',letterSpacing:'0.5px', fontWeight:'bold',marginTop: '10px'
        },
        imgholder:{
            position:'relative',width:'100%', height:'auto',display:'flex'
          }
    }

    return (
        <Grid item xs={12} style={{display: 'flex',justifyContent: "center",marginBottom: '4vh'}}>
            <Link to={'/noticia/' + noticia.title} style={{textDecoration: 'none',color:'black'}}>
            {/* <div style={{display: 'flex', fontFamily:'Bebas Neue Pro Bold'}}> */}
                <div style={{position:'relative',display:'flex',alignItems: 'center',justifyContent: 'center'}} >
                    {/* <div style={{ fontFamily:'Bebas Neue Pro Bold',boxShadow:'2px 2px 15px 0px rgba(0,0,0,0.67)', filter:'blur(2px) brightness(40%)', borderRadius:'15%', backgroundSize:'cover', backgroundImage:"url(" +  noticia.img_sm +")", width:"90vw", height:"90vw", maxWidth:"350px", maxHeight:"350px", backgroundPosition:'center'}}> */}
                    <div style={{width:'100%', backgroundSize:'cover', width:"300px", height:"300px", backgroundPosition:'center',filter:'blur(2px) brightness(40%)',maxWidth:"350px", maxHeight:"350px",}}>
                    <img src={noticia.img_sm} style={{boxShadow:'2px 2px 15px 0px rgba(0,0,0,0.67)', borderRadius:'15%', width:'100%', height:'100%', objectFit:'cover'}}alt="" />

                    </div>
                    
                    {isNew && <div style={{position:'absolute',flexDirection:'column', textAlign:'left',zIndex:500, top:'0px', left:'0px'}}>
                    <span style={{backgroundColor:'#dd2e60',width:'fit-content',padding:'3px', color:'white', fontSize:'26px'}}><b>NUEVO</b></span>
                    </div>}
                    <div style={{position:'absolute',flexDirection:'column', textAlign:'left',zIndex:500, padding:'5px'}}>
                    <p style={{ ...styles.title, lineHeight:'1em'}}>{noticia.title}</p> 

                    </div>
                </div>
               
            {/* </div> */}
            {/* <div style={{border:'2px solid black',marginBottom:'30px',marginTop:'45px'}}></div> */}

                {/* <Article title={noticia.title} description={noticia.subtitle} img={noticia.img_sm}/> */}
            </Link>
        </Grid>
    )
}
export default Post