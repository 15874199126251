import {db} from '../firebase/app'
import { collection,query,where, getDocs} from "firebase/firestore";

export const getUniqueNoticia = async (title) =>{
    // const mapNoticias = new Map();
    const noticia = []
    const q = query(collection(db, "noticias"), where("title", "==", title));
    const querySnapshot = await getDocs(q); 
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        noticia.push(doc.data())
      });
      return noticia[0]

}

// getUniqueNoticia('title').then((res)=>console.log(res))