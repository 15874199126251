import {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import {Link} from 'react-router-dom'
                        

export default function Article(props) {
  const [isLoaded,setIsLoaded] = useState(false) 
  const changeState = () => {setIsLoaded(true)}
  const [loaded,setLoaded] = useState(false)

useEffect(() => {
  if(props.img != null){
    setLoaded(true)
  }
},[isLoaded])

  const styles={
    container:{
      display: 'flex', flexWrap:'wrap', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  fontFamily: "Poppins, sans-serif",marginBottom:'5px', lineHeight: '25px'
    },
    img:{
       boxShadow:'2px 2px 15px 0px rgba(0,0,0,0.67)',paddingBottom: "100%", width:'100%', backgroundSize:'cover', borderRadius:'15%'
    },
    imgholder:{
      position:'relative',width:'100%', height:'auto',display:'flex'
    },
    skeleton:{
      width:'100%',height:'280px'
    },
    circleload:{
      width:'60px',height:'60px',position:'absolute', top:'40%',left:'40%',marginBottom:'-100%', color:'#dd2e60'
    },
    title:{
      fontSize:'22px', margin:'0px',textAlign:'center', color:'#dd2e60', fontWeight:'bold', marginTop: '15px', 
      whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', lineClamp:'2'

    },
    description:{
      fontSize:'16px', 
      height: '3rem',
      textAlign:'left', 
      margin:'0px',
      letterSpacing:'0.5px', 
      fontWeight:'bold',
      marginTop: '10px',  
      overflow:'hidden', 
      height:'74px'
    }

  }
  let palabra = (props.title).toUpperCase().split(' ')
  let description = (props.description).split(' ')
  return (
<Link to={'noticia/' + props.title} style={{textDecoration: 'none',color:'black'}} >
    <div style={styles.container}>
      <img src={props.img}style={{ display: 'none' }} onLoad={() => changeState()}/>
       { isLoaded?
          <div style={{boxShadow:'2px 2px 15px 0px rgba(0,0,0,0.67)', borderRadius:'15%', backgroundSize:'cover',  backgroundPosition:'center',width:"300px", height:"300px", }}>
            <img src={props.img} style={{ boxShadow:'2px 2px 15px 0px rgba(0,0,0,0.67)', borderRadius:'15%', width:'100%', height:'100%', objectFit:'cover'}} alt="" />
          </div>
          :
          <div style={{...styles.imgholder, width:'300px'}}>
          <Skeleton sx={{ bgcolor: 'grey.400' }} variant="rectangular" style={styles.skeleton} />
          <CircularProgress  style={styles.circleload}  />

          </div>
          
          }
          <div style={{width:"300px"}}>
          <p style={styles.title}>{(props.title).toUpperCase()} </p>
          <p style={styles.description}>{props.description}</p>
          </div>


    </div>
</Link>

  );
}