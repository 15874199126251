import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import SocialMedia from './socialMedia'
import Logo from '../img/Logofooter.png'
import {Link} from 'react-router-dom'
const Footer = () =>{

    const styles={
        section:{
            backgroundColor:'#dd2e60'
        },
        text:{
            fontFamily:"Bebas Neue Pro Light",
            color:'white',
            textTransform:'uppercase',
            fontSize:'18px',
        },
        titles:{
        },
        sections:{
            display:'flex',
            flexDirection:'column',
        },

    }
    return(
        <div style={styles.section}>
            <Container maxWidth='lg' style={{paddingTop:'50px'}}>
            <Grid container spacing={3}>

            <Grid item xs={12} md={3} styles={{display:'flex', alignItems: 'center'}}>
                <img src={Logo} alt="" srcSet=""  style={{width:'75%',marginBottom:'30px'}} />
            </Grid>

            <Grid item sm={4} md={3} style={{...styles.text,...styles.sections}}>
                <h3 style={{  display:'flex', margin:0,marginBottom:'15px'}}>Redes de jukeo urbano</h3>
                <div>
                    <SocialMedia showNames={true}/>
                </div> 
            </Grid>

            <Grid item sm={4} md={3} style={{...styles.text,...styles.sections,textAlign: 'left'}}>
            <h3 style={{marginBottom:'-5px', padding:'0px', margin:0}}>Sobre jukeo urbano</h3>
                
                <p>
                <Link to='/terminos-y-condiciones' style={{textDecoration: 'none', color:'inherit'}}>Privacidad de jukeo urbano</Link><br/>
                    
                    <Link to='/conocenos' style={{textDecoration: 'none', color:'inherit'}}>Acerca de jukeo urbano</Link><br/>
                    {/* Trabaja con nosotros  */}
                </p>
            </Grid>

            <Grid item sm={4} md={3} style={{...styles.text,...styles.sections,textAlign: 'left'}}>
            <Link to='/contacto' style={{textDecoration: 'none', color:'inherit'}}>

                <h3 style={{marginBottom:'-5px', padding:'0px', margin:0}}>Contacto</h3>
                </Link>
            <Link to='/#' onClick={
                (e)=>{window.location.href = 'mailto:alangmichelin@gmail.com'
                    e.preventDefault();
                }
            
            } style={{textDecoration: 'none', color:'inherit'}}>
                <p > 
                    Mail
                </p>
            </Link>
            </Grid>
            </Grid>
            </Container>
  
            </div>
    )
}
export default Footer