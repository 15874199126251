import Noticia1 from '../img/noticias/noticia1.jpg'
import Noticia2 from '../img/noticias/noticia2.jpg'
import Noticia3 from '../img/noticias/noticia3.jpg'
import Noticia from "../img/Sombra-noticia-grande.gif";
import Article from './article'
import '../styles/carousel.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useEffect, useState } from "react";

import { Carousel } from 'react-responsive-carousel';


const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 25%)',
    cursor: 'pointer',
    fontSize:'10vh', 
    fontFamily:"Bebas Neue Pro Regular",
    fontWeight:"bold",
    color:'#dd2e60',
    backgroundColor:'transparent',
    outline:'none',
    border:'none',
    '&::hover':{
        color:'red'
    }
};
const ImgCarousel = (props) =>{
    const [noticias,setNoticias] = useState([])
    useEffect(() =>{
        if(props.noticias.length>0){
        setNoticias(props.noticias)
    }
    },[props.noticias])

    return (
        <div  style={{width: "100vw",height:'100%'}}>

        <Carousel 
        autoPlay={true}
        interval={3000}
        verticalSwipe='natural'
        infiniteLoop={true} 
        showArrows={true}  
        showStatus={false} 
        centerSlidePercentage={80}
        showThumbs={false} 
        preventMovementUntilSwipeScrollTolerance={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
                <button onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15}} >
                {'<'}
                </button>)
            }
        renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
                <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                    {'>'}
                </button>)
        }>    
        { props.noticias.length > 0 && noticias.map
            ((noticia,index) =>
                {
                    return(
        
                        <div style={{padding:'20px'}} key={index}>

                        <Article title={noticia.title}  description={noticia.text1} img={noticia.img_sm}/>

                        </div>

                    )
                }
            )
        }            
            
        </Carousel>
        </div>

    )
}

export default ImgCarousel