
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import {uploadFrontSlider} from '../../functions/imgUploadFrontSlider'
import PostPreview from './postPreview'
import {getSliderImgs} from '../../functions/getSliderImgs'
import {useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import {changePostImageVisibility} from '../../functions/getSliderImgs'
import {deletePost} from '../../functions/getAdmins'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height:'90%',
  bgcolor: '#d8d8d8',
  boxShadow: 24,
  p: 4,
  overflowY:'scroll', 
  borderRadius:'35px'
};
const NewsManager = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true)
    props.closeMenu()
  };
  const handleClose = () => setOpen(false);
  const [noticias,setNoticias] = useState(null)
  const [newsToUpdate,setNewsToUpdate] = useState([])


  const addChanges = (newChange) =>{

    setNewsToUpdate(
      newsToUpdate =>{
        return{
         ...newsToUpdate, [Object.keys(newChange)[0]]: Object.values(newChange)[0]
        }
      }
    )

  }

  
  const getNoticias = async()=>{
    const fetchedImages = await getSliderImgs('noticias')
    setNoticias(fetchedImages)
}
  useEffect(() =>{
    getNoticias()
  },[])

  const handleDelete = (postId) =>{
    console.log('delete' + postId)
    deletePost(postId)
    setNoticias(noticias.filter(e=> e.id!==postId))
  }

  const updatePosts = () =>{
    changePostImageVisibility(newsToUpdate,'noticias')
    handleClose()
  }
  return (
    <div>
      <Button style={{color:'rgb(221, 46, 96)'}} onClick={handleOpen} >Administrar noticias</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

            <Grid container>
                <Grid item xs={12}>
                <h2 style={{fontFamily: "Bebas Neue Pro Regular", textAlign: 'center',}}>NOTICIAS DISPONIBLES</h2>
                
                </Grid>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button style={{color:'rgb(221, 46, 96)', display:'flex', flexDirection:'column'}}  onClick={updatePosts}><SaveIcon style={{fontSize:'32px'}}/> Guardar</Button>
                </Grid>

                <Grid item xs={12} style={{display:'flex', flexWrap:'wrap', justifyContent: 'space-evenly',  paddingTop: '10px'}}>
                
                <Grid item xs={12} style={{display:'flex', flexWrap:'wrap', justifyContent: 'center'}}>
                {noticias!=null && noticias.map((noticia,index)=>{
                    return <PostPreview 
                    img={noticia.img_lg} 
                    showAtHomeCarousel={noticia.showAtHomeCarousel} 
                    showAtHomeFeed={noticia.showAtHomeFeed} 
                    title={noticia.title} 
                    key={index} 
                    id={noticia.id} 
                    hasChanged={addChanges}
                    handleDelete={handleDelete}

                    />
                    })
                }
                </Grid>

                </Grid>

            </Grid>

        </Box>
      </Modal>
    </div>
  );
}

export default NewsManager