
import Post from '../components/post.jsx'
import { Container } from "@mui/material";
import { useRef, useEffect, useState } from 'react';
import {getNoticias} from '../functions/getnoticia'
import { useParams } from 'react-router-dom';

const News = (props) =>{
    const [feedNoticias, setFeedNoticias] = useState([])
    const listInnerRef = useRef();
    const {tags} = useParams();

    const handleScroll = () =>{

        if( listInnerRef.current && listInnerRef.current.getBoundingClientRect().bottom <= window.innerHeight){
            console.log("bottom")
            queryNoticias(true)
        }
    }
    const queryNoticias = async (loadNext,tags) =>{

        if(loadNext){
            console.log('should loadnext')
            const queryNoticias = await getNoticias(true)
        setFeedNoticias(feedNoticias => [...feedNoticias, ...queryNoticias])

        }
        else if(tags){
            const queryNoticias = await getNoticias(loadNext,tags)
            console.log(queryNoticias)
            setFeedNoticias(queryNoticias)
        }
        else{
            const queryNoticias = await getNoticias()
            setFeedNoticias(queryNoticias)
            console.log(queryNoticias)
        }
    }
    useEffect(() =>{
        console.log('tags ', tags)
        if(tags){
            queryNoticias(false,tags)
        }
        else{
            queryNoticias()
        }        
        document.addEventListener('scroll', handleScroll)
    },[])

    useEffect(()=>{
        queryNoticias(false,tags)
    },[tags])
    useEffect(() =>{
        console.log(feedNoticias)


    },[feedNoticias])

    return(
        <Container maxWidth="lg" style={{marginTop:'7vh'}} ref={listInnerRef}>
            <Post size={props.size} noticias={feedNoticias} />
        </Container>
    )
}
export default News