import * as React from 'react';
import Article from './article';
import Grid from "@mui/material/Grid";
import {Link} from 'react-router-dom'
import ImgCarousel from './carrousel'
import { useEffect, useState } from "react";
import Loading from './loading'

const NewsFeed = (props) =>{
    const [notices,setNoticias] = useState([])
    useEffect(() => {
        setNoticias(props.noticias)
    }, [props.noticias]);
    
    return (

          <Grid container spacing={2} >
            { props.size<1000 ? <ImgCarousel noticias={notices}/> :
                notices.length ?
                    notices.map((noticia,index) =>
                        {
                            return (
                                <Grid item sm={12} md={4} lg={4} key={index} style={{padding:'50px'}}>
                                    <Link to={'noticia/' + noticia.title} style={{textDecoration: 'none',color:'black'}}>
                                        <Article title={noticia.title} description={noticia.text1} img={noticia.img_sm}/>
                                    </Link>
                                </Grid>
                            )
                        }
            ):
                <Loading/>
            }
            </Grid> 
      );
}

export default NewsFeed