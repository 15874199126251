
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import './logoanimation.css'
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import InputAdornment from '@mui/material/InputAdornment';
import {useState} from 'react'
import { useHistory } from "react-router-dom";


const SearchButton = () => {
  const history = useHistory();

  const [textInput,setTextInput] = useState('')
  const handleSearch = (e) =>{
    if (e.key === 'Enter') {
      // console.log('do validate', textInput)
      history.push('/noticias/'+textInput)

    }
  }
  const handleChange = (e) =>{
    console.log(e.target.value)
    setTextInput(e.target.value)  
}
  // const inputStyle = {
  //   backgroundColor:"#d8d8d8",
  //   display:'flex',
  //   alignItems: 'center',
  //   color: "black",
  //   fontWeight: 500,
  //   fontFamily: "Bebas Neue Pro Regular",
  //   fontSize: "1.2rem",
  //   borderRadius: "50px",
  //   justifyContent: 'left',
  //   width: "100%",
  //   height: "60%",
  //   marginLeft:'2vw'
  //   // justifyContent: 'left',
  //   // fontWeight: "bold",
  // }
  // const SearchInput = styled(TextField)(inputStyle);


  const textStyles = {
    width: "100%",
    height:'80%',
    textTransform: "uppercase",

    '& .MuiOutlinedInput-root': {
      borderRadius:'35px',
      backgroundColor:'#afafaf',
      textAlign: "left",
      fontFamily:'Bebas Neue Pro Regular',
      fontSize:'1.2rem',
      color:'black',
      textTransform: "uppercase",
      
      '& fieldset': {
        border: '2px solid #dd2e60',
        
      },
      '&:hover fieldset': {
        borderColor: '#c72050',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e6e6e6',
  
      },
    },

  }
  const CssTextFieldo = styled(TextField)(textStyles);
  return (

    <CssTextFieldo
    name="searchfield"
    id="outlined-name"
    type="text"
    autoFocus="autoFocus"
    onChange={handleChange}
    onKeyDown={handleSearch}
    value={textInput}
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    placeholder="Buscar..."
  />
  );
};

export default SearchButton;
