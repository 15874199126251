import {CssTextField} from './CreatePost'
import {useState, useEffect} from 'react'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const AddTags = ({styles,handleChange}) =>{
    const [tags,setTags] = useState([])
    const handleTags = (e) =>{
        let commaIndex = e.target.value.indexOf(',')
        if( commaIndex > -1){
            setTags([...tags, e.target.value.slice(0,commaIndex)])
            e.target.value = ''
        }
        
    }

    const handleDelete = (tag) => {
        console.info('You clicked the delete icon.',tag);
        setTags(tags.filter((e)=>e!==tag))
      };

      useEffect(()=>{
          handleChange({target:{'name':'tags','value':tags}})
      },[tags])
    return(
        <div style={{width: '100%'}}>
        <h2
        style={{
        ...styles.newsText,
        textAlign: "left",
        fontSize: "24px",
        }}
        >
            ETIQUETAS
        </h2>
        <div>
            {tags.length>0 && tags.map((tag)=>
            {
                return <Chip label={tag} key={tag} onDelete={()=>{handleDelete(tag)}} variant="outlined"/>

            })}
        </div>
        <CssTextField
            placeholder="Separar las etiquetas por comas"
            type="text"
            name="tags"
            onChange={handleTags}
            sx={{ width: "100%"}}
        />
    </div>

    )
}

export default AddTags