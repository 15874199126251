import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
// import Tiktok from './img/tiktok.svg'
import { Icon, SvgIcon } from '@mui/material';
import { useState, useEffect } from 'react';



const SocialMedia = (props) =>{
const [overrideStyle,setOverrideStyle]=useState({color:'white'})
const [showNames,setShowNames] = useState(false)
useEffect(()=>{
    if(props.styling != null){ 
        setOverrideStyle(props.styling) 
    }
},[props.styling])

useEffect(()=>{
    if(props.showNames === true) { 
        setShowNames(true)
    
    }
},[props.showName])
const TikTok = () =>{
    return(
        <SvgIcon style={{color:'#d8d8d8', width:'24px',height:'24px', padding:'5px',...overrideStyle}}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2859 3333" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd"><path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"/></svg>
</SvgIcon>


    )
}

const style={
    container:{
        display: 'flex',
        flexDirection: showNames ? 'column' : 'row',
        alignItems: 'flex-start',
    }
}

const icons = [
    {'link':'https://www.tiktok.com/@jukeourbano','icon':TikTok,'name':'TikTok'},
    // {'link':'https://www.Twitter.com/jukeoUrbano','icon':TwitterIcon,'name':'Twitter'},
    {'link':'https://www.facebook.com/jukeourbano','icon':FacebookIcon,'name':'Facebook'},
    {'link':'https://www.YouTube.com/jukeoUrbano','icon':YouTubeIcon,'name':'YouTube'},
    {'link':'https://www.instagram.com/jukeourbano_','icon':InstagramIcon,'name':'Instagram'}
]
    return(
        <div style={{...style.container}}>
            {icons.map((icon, index)=>{
                return (
                <span style={{paddingBottom:'5px'}} key={index}>
                <a  href={icon.link} style={{textDecoration: 'none',color:'#d8d8d8', display:'flex', alignItems: 'flex-start'}} target="_blank" rel="noreferrer">
                <SvgIcon component={icon.icon} style={{...overrideStyle, padding:'5px'}}/>
                {showNames && icon.name}
                </a>
                </span>)
            })}
        </div>
    )
}
export default SocialMedia;