import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import {useState,useEffect} from 'react'
import Snackbar from '@mui/material/Snackbar';

export default function AlertMessage({title,description,type, openAlert}) {
    const [msgSeverity,setMsgSeverity] = useState('info')
    const [open, setOpen] = useState(false);
    const alerts =[
        'error',
        'warning',
        'info',
        'success'
    ]
    useEffect(() => {
        setMsgSeverity(alerts.find(e=>(e===type)))
    },[type])
    
    useEffect(()=>{
        console.log(openAlert===true)
        if(openAlert===true) setOpen(true)
    },[openAlert])
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
  return (
    <Snackbar
    open={open}
    onClose={handleClose}
    autoHideDuration={10000}
  >
      <Alert severity={msgSeverity}>
        <AlertTitle>{title}</AlertTitle>
        <strong>{description}!</strong>
      </Alert>
    </Snackbar>
  );
}