import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import NoticiaTemplate from "../../components/noticiatemplate";
import Grid from "@mui/material/Grid";
import FeedIcon from '@mui/icons-material/Feed';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1080px',
  height:'100%',
  bgcolor: '#d8d8d8',
  boxShadow: 24,
  overflow:'scroll',
  padding:0,
  overflowX:'hidden'
};

export default function PreviewNewPost(props) {

  const [open, setOpen] = useState(false);
  const [previewNoticia,setPreviewNoticia] = useState(null);
  const [isDisabled,setIsDisabled] = useState(false)
  useEffect(() =>{
    setPreviewNoticia(props.previewPost)
    console.log(props.previewPost)
  },[props.previewPost])
  const handleOpen = () => {
    props.setInfo()

    setOpen(true)
  };

  const handlePublish = () =>{

    setIsDisabled(true)
    props.publishPost()
  }
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div style={{position: 'fixed', bottom:'10%', left:'auto', right:'8%', zIndex:500}}>
      <Button  style={{backgroundColor:'#d8d8d8', color:'rgb(221, 46, 96)', border:'2px solid rgb(221, 46, 96)', borderRadius:'25px'}} onClick={handleOpen}>
        <div style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>
        <FeedIcon style={{fontSize:'5rem'}}/>
        Vista previa
        </div>
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style} >
        

        <div style={{position:'fixed', left:'auto', right:'50px', top:'50px'}}>
        <Button onClick={handlePublish} disabled={isDisabled} >Publicar</Button>
        </div>
        <Grid container maxWidth="lg"  style={{display:'flex'}}>
        
        <Grid item xs={12}>
          {previewNoticia != null ? <NoticiaTemplate noticia={previewNoticia}/> : null}
          {/* {previewNoticia != null ? <h1>Got noticia</h1> : null} */}
        </Grid>


      </Grid>

        </Box>
      </Modal>
    </div>
  );
}