import { db } from "../firebase/app";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  getDoc,
  doc,
} from "firebase/firestore";

export const getAdmins = async () => {
  // const mapNoticias = new Map();
  const admins = [];
  const querySnapshot = await getDocs(collection(db, "admins"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    //   mapadmins.set(doc.id,doc.data())
    admins.push(doc.id);
  });
  return admins;
};

export const getPermissions = async (userCredentials) => {
  console.log(userCredentials);
  // const currentUser = await user.currentUser
  // const token = await currentUser.getIdTokenResult()
  // console.log(token)
  // if(token.claims.admin){
  //   isAdmin(true)
  // }
};

export const insertDocument = async (email, _collection) => {
  console.log(email);
  console.log(setDoc(doc(db, _collection, email), { admin: true }));
};

export const deleteDocument = async (email, _collection) => {
  console.log(email);
  console.log(deleteDoc(doc(db, _collection, email)));
};

export const insertVideo = async (video, _collection) => {
  console.log(setDoc(doc(db, _collection, video), { display: true }));
};

export const deletePost = async (id) => {
  console.log(deleteDoc(doc(db, "noticias", id)));
};
