import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ModalConfirmation from '../../components/modalConfirmation'

export default function PostPreview(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [showAtHomeFeed, setShowAtHomeFeed] = useState(false);
  const [showAtHomeCarousel, setShowAtHomeCarousel] = useState(false);
  const [changes, setChanges] = useState({});



  useEffect(() => {
    setShowAtHomeCarousel(props.showAtHomeCarousel);
  }, [props.showAtHomeCarousel]);

  useEffect(() => {
    setShowAtHomeFeed(props.showAtHomeFeed);
  }, [props.showAtHomeFeed]);

  useEffect(() => {
    Object.keys(changes).length > 0 && props.hasChanged(changes);
  }, [changes]);

  const handleShowAtHomeFeed = (e) => {
    setChanges((changes) => {
      return {
        ...changes,
        [props.id]: { ...changes[props.id], [e.target.name]: !showAtHomeFeed },
      };
    });
    setShowAtHomeFeed(!showAtHomeFeed);
    // props.checkboxChange()
  };


  const deletePost = () =>{
    props.handleDelete(props.id)
  }

  const handleSetShowAtHomeCarousel = (e) => {
    // changeImageVisibility(props.id,!isChecked)
    setChanges((changes) => {
      return {
        ...changes,
        [props.id]: {
          ...changes[props.id],
          [e.target.name]: !showAtHomeCarousel,
        },
      };
    });
    setShowAtHomeCarousel(!showAtHomeCarousel);
    // props.checkboxChange()
  };
  return (
    <Box sx={styles.boxStyle}>
      <div style={styles.title}>
        <p >{props.title}</p>
      </div>

      <div style={styles.imageContainer} >
        <img
          src={props.img}
          alt=""
          srcSet=""
          style={styles.image}
        />
      </div>
      
        <div style={styles.actionsContainer}>
          <ModalConfirmation buttonText="Borrar" confirmation={deletePost}>
            <p>Borrar la noticia:<br/> <b>{props.title}</b></p>
          </ModalConfirmation>
          {/* <Button onClick={deletePost}>Borrar</Button> */}
          <div style={styles.actionsText}>
            <Checkbox
              checked={showAtHomeCarousel}
              name="showAtHomeCarousel"
              onChange={handleSetShowAtHomeCarousel}
            />
            <p>
              Mostrar en galeria de inicio
            </p>
          </div>
          <div style={styles.actionsText}>
            <Checkbox
              checked={showAtHomeFeed}
              name="showAtHomeFeed"
              onChange={handleShowAtHomeFeed}
            />
             <p>
              Mostrar en noticias de inicio
            </p>
          </div>
        </div>
      
    </Box>
  );
}


const styles = {
    boxStyle:{
      width: 350,
        height: 450,
        backgroundColor: "white",
        boxShadow: "2px 2px 15px 0px rgba(0,0,0,0.67)",
        marginLeft: "15px",
        marginBottom: "15px",
        borderRadius: "35px",
        padding:'5px'
    },
    title:{
      height: "10%", 
      width: "100%", 
      margin: 0, 
      padding: 0,
      textAlign: "center"
    },
    imageContainer:{
      height: "60%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    image:{
      width: "95%", objectFit: "cover", height: "90%" 
    },
    actionsContainer:{
      fontSize: "12px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignContent: 'center'
    },
    actionsText:{

      display:'flex',
      justifyContent: "flex-start",

    },

}