import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import { Button, TextField } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import { styled } from '@mui/system';
import contacto_bg from '../img/contacto_bg.png'
const textStyle = {
    backgroundColor:'#d8d8d8',
    border:'none',
    borderRadius:'15px',
    padding:'15px',
    fontFamily:'Bebas Neue Pro Regular',
    textTransform:'uppercase',
    fontSize:'1.5rem',
    width:'100%',
    margin:'10px'
}
const CustomText = styled('input')(textStyle)
const CustomTextArea = styled('textarea')(textStyle)


const Contacto = () =>{
    const styles={
        h1:{
            fontFamily:'Bebas Neue Pro Regular',
            fontSize:'2.5rem',
            color:'rgb(221, 46, 96)',
            letterSpacing:'2px',
            textAlign:'center',
        },
        text:{
            fontFamily:'Poppins, sans-serif', 
            fontWeight:'bold',
            textAlign:'left',
            fontSize:'1.2rem'
        },
        subtitles:{
            fontFamily:'Poppins, sans-serif', 
            fontWeight:'bold',
            textAlign:'left',
            fontSize:'1.4rem',
            color:'rgb(221, 46, 96)',
            marginTop:'2rem'
        }
    }
    return(
      <div style={{marginTop: '7vh'}}>
      <div style={{ justifyContent: 'center', backgroundImage:'url('+contacto_bg+')', backgroundSize:'cover', display:'flex'}}>

      {/* <div style={{overflow:'hidden', height:'100vh', width:'100vw',justifyContent: 'center', backgroundImage:'url('+contacto_bg+')',  position:'absolute', zIndex:-3, display:'flex'}}> */}
      {/* <div style={{display:'flex', backgroundColor:'#d8d8d8',  width:'30%', height:'100%', minWidth:'800px', minHeight:'800px', position:'relative', zIndex:5}}>
          </div> */}
        {/* </div> */}

        <div style={{display:'flex', backgroundColor:'#d8d8d8',  width:'30%', height:'100%', minWidth:'800px', minHeight:'800px', position:'relative', zIndex:5}}>

        <Grid container xs={12} style={{marginTop: "7vh", display:'flex',  flexDirection: 'column', alignItems: 'center', flexWrap: 'nowrap'}}>


          
        <Grid item xs={12} style={{textAlign:'left', padding:0}}>
            <h1 style={styles.h1}>CONTACTO</h1>
        </Grid>

          <Grid item xs={12} style={{backgroundColor:'#dd2e60', margin:'25px', padding:'50px', display:'flex', borderRadius:'35px',}}>
        <form action="https://formsubmit.co/alan17pc@gmail.com" method="POST" style={{width:'100%'}}>
        <div style={{display:'flex'}}>
              <CustomText
                required
                label="Nombre"
                type="text"
                name="nombre"
                placeholder="Nombre"
              />
              <CustomText
                label="Apellido"
                type="text"
                name="Apellido"
                variant="filled"
                placeholder="Apellido"
              />
        </div>
        <div style={{display:'flex'}}>
              <CustomText
                required
                label="Correo"
                type="email"
                name="Correo"
                placeholder="Correo"
              />
              <CustomText
                label="Telefono"
                type="text"
                name="Telefono"
                variant="filled"
                placeholder="Telefono"
              />
        </div>
        <div style={{display:'flex'}}>
              <CustomTextArea
                required
                rows={6}
                label="Message"
                type="text"
                name="text"
                variant="filled"
                placeholder="Mensaje"
              />
        </div>

            <br />
            <Button
              type="submit"
              style={{
                backgroundColor: "#d8d8d8",
                color: "rgb(221, 46, 96)",
                borderRadius:'15px',
                padding: "15px",
              }}
            >
              {" "}
              <SendIcon /> Enviar </Button>
          </form>

        </Grid>

        <p style={{...styles.text, color:'black'}}>New York, Estados Unidos <br/>
        info@jukeourbano.com</p>
        </Grid>
        </div>
        </div>

        </div>
    )
}

export default Contacto