import CircularProgress from '@mui/material/CircularProgress';
const Loading = () =>{



    return(
            <div style={{position:'relative', width:'100vw', height:'100vh', display: 'flex'}}>

<CircularProgress  style={{padding:0, margin:0, top:'50%',left:'50%', position:'absolute', color:'#dd2e60'}}/>
</div>
    )
}

export default Loading