import { getStorage, ref, uploadBytesResumable,getDownloadURL } from "firebase/storage";
import { storage, app} from '../firebase/app'


export const uploadSomething = async (file, path) =>{

    const storage = getStorage();
    const storageRef = path == undefined ? ref(storage, 'images/'+file.name) : ref(storage, path+'/'+file.name)
    const uploadTask = uploadBytesResumable(storageRef, file);

return uploadTask.then(() => {
    return getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

      return downloadURL
          });
    }).then((res)=> {return res})

}