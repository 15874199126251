import React, { Component, useState, useEffect } from "react";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import {useRef}from 'react'
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
const DisplayImage = (props) => {
    const inputFile = useRef(null) 
    const [file,setFile] = useState(null)
    const [newImage,setNewImage ] = useState(null)
    useEffect(()=>{
      if(newImage!= null){

      props.imgFile(
        {
          'imgfile':file,
          'imgpreview': newImage
        }
      )
      // setNewImage(null)
      // setFile(null)
    }

    },[newImage])



  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setNewImage( URL.createObjectURL(img))
      setFile(img)
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
   inputFile.current.click();

  };
    return (
 
          <div onClick={onButtonClick} style={{height:'100%', width:'90%', position:'relative', display: 'inline-block', overflow: 'hidden',cursor: 'pointer'}}>
            <input type="file" id="myImage" ref={inputFile} style={{display: 'none'}}  onChange={onImageChange} />
            <div >
            <div style={{ display: newImage===null ?  'none' : 'block'}} >
              {newImage!==null && <div style={{
              backgroundImage: 'url('+newImage+')',
              display:'block',
              position:'absolute',
              top:' 50%',
              left:' 50%',
              minHeight:' 100%',
              minWidth:' 100%',
              transform:' translate(-50%, -50%)',
              borderRadius: "30px",
              backgroundSize:'cover',
              backgroundPosition:'center'
            }}>

            </div> }

        <IconButton aria-label="Example" style={{top:'-80px',left:'40%', position:'relative', backgroundColor:'#dd2e60', color:'white'}}>
          <EditIcon style={{fontSize: '3rem'}}/>
        </IconButton>
              <p style={{ color:'white', zIndex:50, position: 'relative', top:"-50px"}}>Click para cambiar la imagen</p>
            </div>
            </div>
            <div style={{width: '95%', alignItems: 'center', justifyContent: 'center', height: '95%', border: '3px solid #dd2e60', borderRadius:'50px', display: newImage===null ? 'flex' : 'none'}} >
            <ImageSearchIcon style={{fontSize:'15vh', color:'#dd2e60'}}/>
            </div>

          </div>

    );

}
export default DisplayImage;


// class DisplayImage extends Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         image: null
//       };
//       // const inputFile = useRef(null) 
  
//       this.onImageChange = this.onImageChange.bind(this);
//     }
  
//     onImageChange = event => {
//       if (event.target.files && event.target.files[0]) {
//         let img = event.target.files[0];
//         this.setState({
//           image: URL.createObjectURL(img)
//         });
//       }
//     };
  
//     render() {
//       return (
//         <div>
//           <div>
//             <div>
//               <img src={this.state.image} />
//               <div style={{width: '100%', border: '1px solid black'}}>
//               <ImageSearchIcon style={{fontSize:'15vh'}}/>
//               </div>
//               <input type="file" name="myImage" onChange={this.onImageChange} />
//             </div>
//           </div>
//         </div>
//       );
//     }
//   }
//   export default DisplayImage;
  