import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Input from '@mui/material/Input';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {youtube_parser} from '../../functions/youtubeparser'
import {insertVideo} from '../../functions/getAdmins'
import {updateVideo} from '../../functions/getVideos'
const style = {
    display:'flex',
    alignItems: 'center',
    flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddVideo({id, videoUploaded}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newVideo,setNewVideo] = useState('')
  const handleNewVideo = () =>{
  const videoToAdd = youtube_parser(newVideo)
  if(videoToAdd !== false){
    updateVideo(videoToAdd,id)
    videoUploaded(videoToAdd)
  }
  handleClose()
  }
  return (
    <>
      <Button onClick={handleOpen} aria-label="accept" style={{display:'flex', flexDirection:'column', color:'rgb(221, 46, 96)'}}>
        <AddCircleIcon style={{fontSize:'128px'}}/>
        Añadir video
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Agregar video
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Input placeholder="Ingresar link del video" autoFocus onChange={(e)=>{setNewVideo(e.target.value)}} />
          </Typography>
            <span>
                <IconButton aria-label="accept" style={{color:'green'}}>
                <CheckCircleIcon onClick={handleNewVideo}/>
                </IconButton>
                <IconButton aria-label="cancel"  style={{color:'red'}}>
                <CancelIcon onClick={handleClose}/>
                </IconButton>
            </span>
        </Box>
      </Modal>
    </>
  );
}