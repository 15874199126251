import {db} from '../firebase/app'
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

export const getVideos = async () =>{
    // const mapNoticias = new Map();
    const videos = []
    const querySnapshot = await getDocs(collection(db, 'videos'));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
    //   mapNoticias.set(doc.id,doc.data())
    let data = {id:doc.id,data:doc.data()}

    videos.push(data)
    });
    return videos
}

export const updateVideo = async (newVideo,id) =>{

      await updateDoc(doc(db,'videos',id), {videoid:newVideo});
    
  
}