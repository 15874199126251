import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useHistory } from "react-router-dom";

const Tags = ({tags}) =>{
  const history = useHistory();
  const style={
    title:{
        textAlign: "left",
        fontFamily: "Bebas Neue Pro Regular",
        fontSize: "1.5rem"
    }
  }
  const handleClick = (tag) => {
    history.push('/noticias/'+tag)
  };

  return (
      <>
      <p style={style.title}>Etiquetas</p>
    <Stack direction="row" spacing={1}>
        {tags.map((tag)=>{
            return <Chip label={tag} onClick={()=>{handleClick(tag)}} />
        })}
    </Stack>
    </>

  );

}

export default Tags