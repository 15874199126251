import { app} from '../firebase/app'
import { getAuth,signOut  } from "firebase/auth";



export const logout = () =>{
  const auth = getAuth()
    signOut(auth).then(function() {
        console.log('sign out succesful')
      }, function(error) {
        console.error('error',error)
    });
} 