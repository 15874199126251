import Grid from "@mui/material/Grid";
import NewsFeed from "../components/newsthumb.jsx";
import { Container } from "@mui/material";
import YoutubeSection from "../components/youtubeembed.jsx";
import Footer from "../components/footer.jsx";
import { useEffect, useState } from "react";
import CarouselHome from "../components/carouselHome";
const Home = (props) => {
  // console.log(props.feed)
  const [noticias, setNoticias] = useState({});
  const [newsForHome, setNewsForHome] = useState([]);
  const [newsForCarousel, setNewsForCarousel] = useState([]);
  const [videos, setVideos] = useState([])
  useEffect(() => {
    setNoticias(props.noticias);
    if (props.noticias.length > 0) {
      setNewsForCarousel(
        props.noticias.filter((e) => e.showAtHomeCarousel == true)
      );
      setNewsForHome(props.noticias.filter((e) => e.showAtHomeFeed == true));
    }
    if(props.videos.length > 0 ){
      setVideos(props.videos)
    }
  }, [props.noticias, props.videos]);

  const styles = {
    banner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    bannerDiv: {
      width: "100vw",
      position: "relative",
      left: "50%",
      marginLeft: "-100%",
    },
    imgText: {
      color: "white",
      fontFamily: "Blank River",
      fontSize: "5vw",
      fontWeight: "lighter",
      letterSpacing: "1vh",
    },
    newsText: {
      textAlign: "center",
      fontFamily: "Bebas Neue Pro Regular",
      fontSize: "3rem",
      letterSpacing: "3px",
      lineHeight: "4rem",
    },
    interviewDiv: {
      position: "relative",
      backgroundColor: "black",
      width: "100%",
    },
  };

  return (
    <div style={{marginTop:'7vh'}}>
      <Container maxWidth="lg">
        <Grid item xs={12} style={styles.banner}>
          <div style={styles.bannerDiv}>
            <CarouselHome images={newsForCarousel} size={props.size}/>
          </div>
        </Grid>
        <Grid item xs={12} >
          <h2 style={styles.newsText}>NOTICIAS</h2>
        </Grid>
        <Grid item xs={12} style={{marginBottom:'5vh'}}>
          <NewsFeed size={props.size} noticias={newsForHome} />
        </Grid>
        <Grid item xs={12} style={styles.banner}>
          <div style={{ backgroundColor: "black", ...styles.bannerDiv }}>
            <div style={{ ...styles.newsText, color: "white" }}>
              <h2>ENTREVISTAS</h2>
            </div>
            <YoutubeSection videos={videos} />
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </Container>

      <Footer />
    </div>
  );
};

export default Home;
