
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import {uploadFrontSlider} from '../../functions/imgUploadFrontSlider'
import PostPreview from './postPreview'
import {getVideos} from '../../functions/getVideos'
import {useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import {changePostImageVisibility} from '../../functions/getSliderImgs'
import Checkbox from "@mui/material/Checkbox";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddVideo from './addVideoModal'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height:'80%',
  bgcolor: '#d8d8d8',
  boxShadow: 24,
  p: 4,
  borderRadius:'35px'
};


export const VideoThumbnail = (props) =>{
    
    const [video,setVideo] = useState('')
    const [isHover, setIsHover] = useState(false)
    useEffect(() => {
      setVideo(props.video.data.videoid)
    }
    ,[props])

    const handleMouseEnter = () =>{
      setIsHover(true)
    }
    const handleMouseLeave = () =>{
      setIsHover(false)
    }

    const handleNewVideo = (newVideo) =>{
      setVideo(newVideo)
    }
  return(
    <>
      {props.video.id? 
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleMouseLeave}>
        <div style={{width:'100%',height:'100%',display: 'flex', flexDirection: 'column',alignItems: 'center',justifyContent: 'center'}}>
        <div style={{zIndex:500, width:'100%', height:'100%', position:'absolute',  display: 'flex', alignItems:'center',justifyContent: 'center', backgroundColor:'rgba(0, 0, 0, 0.9)',
        display: isHover ?  'flex' : 'none'
      }}>
        <AddVideo videoUploaded={handleNewVideo} id={props.video.id}/>
        </div>
        <img
          src={`https://img.youtube.com/vi/${video}/0.jpg`}
          alt="video"
          style={{width:'100%', height:'80%', objectFit:'cover'}}

          srcSet=""
        />
        </div>
        <div style={{display: 'flex', flexDirection:'column'}}>
        <a href={'http://youtu.be/'+video}>http://youtu.be/{video}</a>
        </div>
      </div>
      :  
      <AddVideo/>
    }
    </>
  )
}

const VideoManager = (props) => {
  const [showVideo,setShowVideo] = useState(false)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [videos,setVideos] = useState(null)
  const [videosToUpdate,setvideosToUpdate] = useState([])



  const addChanges = (newChange) =>{

    setvideosToUpdate(
      videosToUpdate =>{
        return{
         ...videosToUpdate, [Object.keys(newChange)[0]]: Object.values(newChange)[0]
        }
      }
    )

  }


  const _getVideos = async()=>{
    const fetchedVideos = await getVideos()
    console.log(fetchedVideos)
    setVideos(fetchedVideos)
}
  useEffect(() =>{
    _getVideos()
  },[])

  const updatePosts = () =>{
    changePostImageVisibility(videosToUpdate,'videos')
  }
  return (
    <div>
      <Button style={{color:'rgb(221, 46, 96)'}} onClick={handleOpen}>Administrar videos</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container>
                <Grid item xs={12}>
                <h2 style={{fontFamily: "Bebas Neue Pro Regular", textAlign: 'center',}}>VIDEOS DISPONIBLES</h2>
                
                </Grid>

                <Grid item xs={12} style={{display:'flex', flexWrap:'wrap', justifyContent: 'space-evenly',  borderRadius:'35px'}}>
                
                <Grid item xs={10} style={{display:'flex', flexWrap:'wrap'}}>

                {videos != null && videos.map((video,index)=>{
                    return (
                    <div style={{ display:'flex', flexDirection:'column', marginLeft:'15px', marginBottom:'150px',height: '200px', width:'300px', position:'relative'}} key={index}>
                      <VideoThumbnail video={video} />

                     
                      </div>)
                    })
                }

                </Grid>

                </Grid>

            </Grid>

        </Box>
      </Modal>
    </div>
  );
}

export default VideoManager