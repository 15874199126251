import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SocialMedia from '../socialMedia.jsx'
import NavButton from './navbutton.jsx';
import MenuIcon from '@mui/icons-material/Menu';
const style = {
  position: 'absolute',
  top: '0px',
  left: 'auto',
  right:'5vw',
  //   transform: 'translate(75%, 5%)',
  width: 300,
  // height: '50vh',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  bgcolor: '#d8d8d8',
  boxShadow: 24,
  borderRadius:'30px',
  color:'#dd2e60',
  fontFamily:'Bebas Neue Pro Regular',
  fontSize:'20px',
  textAlign: 'center',
  paddingTop:'15px',
  paddingBottom:'15px',
  buttonStyling:{
    color:'#c72a57',
    fontFamily: "Bebas Neue Pro Regular",
    fontSize: "18px",
    '&:focus':{
      // backgroundColor:'white',
      color:'black'
    }
  },
  socialIcons:{
    color:'#c72a57',
    width:'48px',
    height: "48px",

  }
};

export default function MobileMenu(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div style={{
         color:'black',fontWeight:'bold', position:'relative',zIndex:5000}} onClick={handleOpen}><MenuIcon style={{ fontSize: "40px", color:'white'}}/></div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div style={{fontFamily: "Blank River", display:'flex', justifyContent:'flex-end',fontSize: "6vw",color:'black',marginRight:'15%',color:'#c72a57',fontWeight:'bold'}} 
        onClick={handleClose}><MenuIcon style={{ fontSize: "40px"}}/></div>

          {props.buttonsText.map((button,index) =>{
              return <NavButton styling={style.buttonStyling} key={index} url={button.link} text={button.name} index={index} closeModal={handleClose}  /> 
          })}
          <div style={{display:'flex',justifyContent:'center'}}>
          <SocialMedia styling={style.socialIcons}/>
          </div>
        </Box>
      </Modal>
    </div>
  );
}