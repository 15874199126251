import { db } from "../firebase/app";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
  where,
} from "firebase/firestore";

let lastVisible;
let querySnapshot;
const pageSize = 6;
const field = "date";
const ref = collection(db, "noticias");
export const getNoticias = async (showNext, searchTags) => {
  const noticias = [];
  if (!showNext) {
    if (searchTags) {
      console.log("in search with tags" + searchTags);
      querySnapshot = query(ref, where("tags", "array-contains", searchTags));
    } else {
      querySnapshot = query(ref, orderBy(field, "desc"), limit(pageSize));
    }
  } else if (showNext === true && lastVisible !== undefined) {
    querySnapshot = query(
      ref,
      orderBy(field, "desc"),
      startAfter(lastVisible),
      limit(pageSize)
    );
  }

  let docSnapshots = await getDocs(querySnapshot);
  console.log(docSnapshots);
  lastVisible = docSnapshots.docs[docSnapshots.docs.length - 1];

  docSnapshots.forEach((doc) => {
    noticias.push(doc.data());
  });

  return noticias;
};

export const getNoticiasHome = async () => {
  // Create a query against the collection.
  const noticiasHome = [];
  const _query = query(ref, where("showAtHomeFeed", "==", true));
  const queryResults = await getDocs(_query);
  queryResults.forEach((e) => {
    console.log(e);
    noticiasHome.push(e.data());
  });
  return noticiasHome;
};
