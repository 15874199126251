// import Background from "../components/background.jsx";
import Grid from "@mui/material/Grid";
// import ImgLoader from "../components/imgloader.jsx";
import Input from "@mui/material/Input";
import DisplayImage from "../../components/imageupload.jsx";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Noticia from "../noticia.jsx";
import TextField from '@mui/material/TextField';
import NoticiaTemplate from "../../components/noticiatemplate";
import {insertNoticia} from '../../functions/insertnoticia.js'
import { uploadSomething } from "../../functions/imgupload";
import PreviewNewPost from './previewNewPost'
import MyEditor from './textEditor'
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import AlertMessage from '../../components/alert'
import AddTags from './addTags.jsx'
const textStyles = {

  '& label.Mui-focused': {
    color: 'green',

  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',

  },
  '& .MuiOutlinedInput-root': {
    borderRadius:'35px',
    backgroundColor:'#afafaf',
    textAlign: "left",
    '& fieldset': {
      border: '2px solid #dd2e60',
    },
    '&:hover fieldset': {
      borderColor: '#c72050',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#e6e6e6',

    },
  },
}
export const CssTextField = styled(TextField)(textStyles);
const CssInput = styled(Input)(textStyles);

const CreateNew = () => {
  const history = useHistory();

  const styles = {
    newsText: {
      textAlign: "center",
      fontFamily: "Bebas Neue Pro Regular",
      fontSize: "2.5rem",
      letterSpacing: "3px",
    },
    inputs:{
      backgroundColor:'#afafaf'
    },

  };
  const [noticia, setNoticia] = useState({});

  const [showPreviewNoticia, setShowPreviewNoticia] = useState(null);
  const [isChecked1,setIsChecked1] = useState(false)
  const [isChecked2,setIsChecked2] = useState(false)
  const [imgsToUpload,setImgsToUpload] = useState([])
  const [callAlert,setCallAlert] = useState(false)
  const handleChange = (e) => {
    setNoticia({
      ...noticia,
      [e.target.name]: e.target.value,
    });

  };




  const uploadImage = async (e,name) =>{


      if (e != null) {
        // const imgUpload = await uploadSomething(e)
        // console.log(imgUpload)
        


      setImgsToUpload( imgsToUpload =>{
        return{
          ...imgsToUpload,[name]:e
        }
      } )
      handleChange({
        target: {
          name: name,
          // value: imgUpload,
          value: e.imgpreview,
        },
      });
      }
 
    
    // const urlres = await imgUrl(e)
    // console.log('urlresponse :', urlres)
  }
  function StructureNoticia(title, subtitle,videourl,text1,text2, img_lg,img_sm, showathome, showAtHomeCarousel,tags){

    this.title = title !== undefined ?  title : ''
    this.subtitle = subtitle !== undefined ? subtitle : ''
    this.videourl = videourl !== undefined ?  videourl : ''
    this.text1 = text1 !== undefined ?  text1 : ''
    this.text2 = text2 !== undefined ?  text2 : ''
    this.img_lg = img_lg !== undefined ?  img_lg : ''
    this.img_sm = img_sm !== undefined ?  img_sm : ''
    this.showAtHomeFeed = showathome !== undefined ?  showathome : false
    this.showAtHomeCarousel = showAtHomeCarousel !== undefined ?  showAtHomeCarousel : false
    this.tags = tags
  };


  const updateInfo = async () => {



    const noticiatemplate = new StructureNoticia(
      noticia.title,
      noticia.subtitle,
      noticia.videourl,
      noticia.text1,
      noticia.text2,
      noticia.img_lg,
      noticia.img_sm,
      noticia.showAtHomeFeed,
      noticia.showAtHomeCarousel,
      noticia.tags
      )
      // console.log(noticiatemplate)

  setShowPreviewNoticia(noticiatemplate)

  };


  const getUrls = async () =>{
    const imgs=[]
    for (const img in imgsToUpload) {
      if (Object.hasOwnProperty.call(imgsToUpload, img)) {
        const element = imgsToUpload[img];

        element.url= await uploadSomething(element.imgfile)
        imgs.push({[img]:element})
      }
    }
    return imgs
  }

  const publishPost = async () =>{
    let newUrls = await getUrls()
    for (const iterator of newUrls) {
      showPreviewNoticia[Object.keys(iterator)[0]] = iterator[Object.keys(iterator)[0]].url
    }
    insertNoticia(showPreviewNoticia)
    setCallAlert(true)
    setTimeout(()=>{history.push('noticia/'+showPreviewNoticia.title)},10000)
    
  }

  const handleCheckbox1 = (e) =>{

    handleChange({
      target: {
        name: 'showAtHomeFeed',
        value:!isChecked1},
      })
    setIsChecked1(!isChecked1)

  }

  const handleCheckbox2 = (e) =>{

    handleChange({
      target: {
        name: 'showAtHomeCarousel',
        value:!isChecked2},
      })
    setIsChecked2(!isChecked2)
      
  }

  return (

      


      <Container maxWidth="lg" style={{ padding: "10px", marginTop: "10vh", display: "flex", overflow: "hidden" }}>
        <AlertMessage title='Noticia publicada' description='Seras redirigido a la seccion de noticias en 10 segundos' type='success' openAlert={callAlert}/>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ ...styles.newsText }}>
            <h1 style={{ textAlign: "left", fontSize: "48px" }}>OPCION 1</h1>
          </Grid>

          <Grid item xs={12} style={{height: "500px", width:'100%', position: "relative", display:'inline-block', overflow: "hidden", margin:0}}>
            <DisplayImage
              imgFile={(e)=>{ 
                uploadImage(e,'img_lg')
              }}
            />
          </Grid>
          <PreviewNewPost previewPost={showPreviewNoticia} setInfo={updateInfo} publishPost={publishPost} />

          <Grid item xs={12}>

            <div style={{fontSize:'12px', marginTop:'15px', display:'flex',alignItems: 'flex-start',justifyContent:'flex-start', flexDirection:'column', flexWrap:'wrap'}}>
            <span><Checkbox checked={isChecked1} onClick={handleCheckbox1} name='showAtHomeFeed' />Mostrar imagen principal en galeria de home </span>
            <span><Checkbox checked={isChecked2} onChange={handleCheckbox2} name='showAtHomeCarousel'/>Mostrar noticia en home </span>
            </div>
          </Grid>
          <h1
            style={{ ...styles.newsText, textAlign: "left", fontSize: "24px" }}
          >
            TITULO
          </h1>
          {/* <input type="text" id="name" name="name" required
                minlength="4" maxlength="100" size="100vw"/> */}
          <CssTextField
            type="text"
            name="title"
            onChange={handleChange}
            sx={{ borderRadius:'35px', width:'100%' }}
          />
          <Grid item xs={12}>
            <h2
              style={{
                ...styles.newsText,
                textAlign: "left",
                fontSize: "24px",
                width:'100%'
              }}
            >
              TEXTO 1
            </h2>
          </Grid>
          <Grid container style={{height: "300px"}}>

          <Grid item xs={8}>

{/* <MyEditor/> */}

            <CssTextField
              sx={{width: "100%",height: "100%"}}
              multiline
              rows={12}
              name="text1"
              type="text"
              onChange={handleChange}
            />
          </Grid>


          <Grid
            item
            xs={4}
            style={{ paddingLeft:'15px', height: "300px"}}
          >
            <DisplayImage
              imgFile={(e)=>{ 
                uploadImage(e,'img_sm')
              }}

            />
          </Grid>
          </Grid>
          <Grid item xs={12}>
            <h2
              style={{
                ...styles.newsText,
                textAlign: "left",
                fontSize: "24px",
                
              }}
              
            >
              SUBITUTULO
            </h2>

            <CssTextField
              sx={{ width: "100%" }}
              type="text"
              name="subtitle"
              onChange={handleChange}

            />

            <h2
              style={{
                ...styles.newsText,
                textAlign: "left",
                fontSize: "24px",
              }}
            >
              URL VIDEO YOUTUBE
            </h2>

            <CssTextField
              type="text"
              name="videourl"
              onChange={handleChange}
              sx={{ width: "100%"}}
            />

            <h2
              style={{
                ...styles.newsText,
                textAlign: "left",
                fontSize: "24px",
              }}
            >
              TEXTO 2
            </h2>
              
            <CssTextField
              sx={{width: "100%",height: "100%"}}
              multiline
              rows={12}
              name="text2"
              type="text"
              onChange={handleChange}
            />
            
          </Grid>
          <AddTags styles={styles} handleChange={handleChange}/>
        </Grid>
    </Container>


  );
};

export default CreateNew;
