import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import Banner from '../img/img_quienes_somos.jpg'

const Conocenos = () =>{

    return(
        <Grid container style={{marginTop: "7vh", padding:0}}>
        <Grid item xs={12} style={{textAlign:'left'}}>
        <img src={Banner} alt="" srcset="" style={{width: "100%"}} />
        <div style={{paddingLeft:'5%' ,paddingRight:'5%'}}>
        <h1 style={{fontFamily:"Bebas Neue Pro Regular", letterSpacing: "1.5px", fontSize: "3.2rem", color:'rgb(221, 46, 96)'}}>¿QUIENES SOMOS?</h1>
        <p style={{fontFamily:'Poppins, sans-serif', fontWeight:'bold', fontSize:'1.3rem'}}>
        Un medio de comunicación, creado para engrandecer la música urbana y el mundo del entretenimiento, a través de entrevistas, noticias, videos, eventos y opiniones; que permitirán destacar y promover el talento de artistas y personalidades nuevas como también ya posicionadas.
        </p>
        </div>
        </Grid>
      </Grid>
    )
}

export default Conocenos