import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import NewsManager from './admin/newsManager'
import SimpleCard from '../components/card'
const Admin = (props) =>{

    return(
        <Grid container style={{marginTop:'20vh', width:'100%', display: 'flex',justifyContent: 'center' }}>
        <Grid item xs={12}>
        <h1>Administracion de Jukeo Urbano</h1>
        </Grid>
        <Grid item xs={12} style={{display:'flex',justifyContent: 'center'}}>

        {/* <SimpleCard user={props.user}/> */}

        </Grid>
        <Button>Crear noticia</Button>
        <NewsManager/>
        </Grid>
    
    )
}

export default Admin