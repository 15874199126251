import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../styles/youtubeembed.css";
import Grid from "@mui/material/Grid";
import {youtube_parser} from '../functions/youtubeparser'


const YoutubeSection = (props) => {
      const [videoids,setVideoids] = useState([])

      const [actualVideo, setActualVideo] = useState('');
      useEffect(() =>{
      if(props.videos.length>1){
        setVideoids(props.videos.map( e => {return e.data.videoid}))
      }else{
        setVideoids(props.videos)
      }
      },[props.videos])


        useEffect(()=>{
          setActualVideo(videoids[0])
        },[videoids])
      

      

      
      const style = {
        thumbSection: {
          display: "flex",
          flexDirection: "row",
        },
        thumb: {
          width: "10vw",
          minWidth: "100px",
          '&::hover':{
            border:'2px solid red',

          },
        },
      };

      const changeVideo = (video) => {
        setActualVideo(video);
      };
      return (
        <div
          style={{ display: "flex", flexWrap: "wrap", justifyContent: "center",marginBottom: "6vh"}}
        >
          <Grid item xs={12} lg={5}>
            
          {actualVideo!=='' && <YoutubeEmbed embedId={actualVideo} />}

            <div
              style={style.thumbSection}
              // style={{width:'20%', height:'480px', display:'flex', flexDirection:'column', marginLeft:'2vw'}}
            >
              {videoids.length> 1 && videoids.map((vid, index) => {
                    return (
                      <img
                        src={`https://img.youtube.com/vi/${vid}/0.jpg`}
                        key={index}
                        style={{...style.thumb}}
                        alt="video"
                        srcSet=""
                        onClick={() => {
                          changeVideo(vid);
                        }}
                      />
                    );
                  })
                }
            </div>
          </Grid>
        </div>
      );
};

export const YoutubeEmbed = ({ embedId }) => {
  const [videoPlaying, setVideoPlaying] = useState('')
  useEffect(() => {
    setVideoPlaying(embedId)
  },[embedId])
  
    return(
        <div className="video-responsive">

         <iframe
            width="853"
            height="480"
            style={{width:'100%'}}
            src={`https://www.youtube.com/embed/${videoPlaying}`}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            autoPlay="1"
          />

        </div>  
        

    )
     
  
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeSection;
