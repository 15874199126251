import {useEffect,useState} from 'react'
import {app} from '../firebase/app'
import { getAuth } from "firebase/auth";

const useFirebaseAuthentication = (app) => {
    const [authUser, setAuthUser] = useState(null);
    useEffect(() =>{
    const auth = getAuth()

       const unlisten = auth.onAuthStateChanged(
          authUser => {
            authUser
              ? setAuthUser(authUser)
              : setAuthUser(null);
          },
       );
       return () => {
           unlisten();
       }
    }, []);

    return authUser
}

export default useFirebaseAuthentication;