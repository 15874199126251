import "./App.css";
import { useEffect, useState } from "react";
import ButtonAppBar from "./components/navbar/navbar.jsx";
import Home from "./pages/home.jsx";
import News from "./pages/news.jsx";
import NewsSearch from "./pages/newsSearch.jsx";
import { Switch, BrowserRouter ,Route  } from 'react-router-dom';
import Noticia from './pages/noticia.jsx'
import { useLocation } from 'react-router-dom';
import NewsCreator from './pages/CreatePost/CreatePost';
import CustomizedMenus from "./components/adminmenu";
import {getNoticiasHome} from './functions/getnoticia'
import {app, role} from './firebase/app'
import 'firebase/compat/auth'
import {userVerification} from './functions/userVerification'
import useFirebaseAuthentication from './hooks/firebaseauth'
import Admin from './pages/admin'
import {getSliderImgs} from './functions/getSliderImgs'
import {getVideos} from './functions/getVideos'
import Conocenos from './pages/conocenos'
import TerminosCondiciones from './pages/tyc'
import Contacto from './pages/contacto'
import {getPermissions} from './functions/getAdmins'
import {
  getAuth
} from "firebase/auth";
function App() {
  
  const [size, setSize] = useState(window.innerWidth);
  const [noticiasHome,setNoticiasHome] = useState({})
  const [admin,isAdmin] = useState(false)
  const [sliderImages,setSliderImages] = useState(null)
  const [user,setUser] = useState(null)
  const authUser = useFirebaseAuthentication(app)
  const [videos,setVideos] = useState([])
  window.addEventListener("resize", (e) => {
    setTimeout(setSize(e.target.innerWidth), 1000);
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps

// test()
  const getUserPermissions = async (authUser) =>{
    // console.log(authUser)
    // const _currentUser = await authUser.currentUser
    const token = await authUser.getIdTokenResult()
    if(token.claims.admin){
      isAdmin(true)
    }
  }


  useEffect(()=>{
    setUser(authUser)
    if(authUser!= null && authUser != undefined){

      getUserPermissions(authUser)

    }




  },[authUser])
  const callData = async ()=>{
    //TO-DO: Cambiar funcion a otra que solo traiga las noticias que tinene que mostrarse en home
    const queryNoticias = await getNoticiasHome();
    console.log(queryNoticias)
    setNoticiasHome(queryNoticias)
    const sliderImgs = await getSliderImgs('noticias')
    setSliderImages(sliderImgs)
    const videos = await getVideos();
    setVideos(videos)
  }


  useEffect(()=>{

    callData()
  },[])
  const onLogout = () =>{
    if(admin){
      isAdmin(false)
    }
  }

  const useScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {

      window.scrollTo({ top: 0 });
      // scroll to the top of the browser window when changing route
      // the window object is a normal DOM object and is safe to use in React.
    }, [location]);
  };
  
  return (
    <div className="App" style={{ maxWidth: '100%', overflowX: 'hidden'}}>
      <BrowserRouter basename='/'>
            <ButtonAppBar size={size} scrollTo={useScrollToTop} user={user} onLogout={onLogout} />    
            {admin && <CustomizedMenus/>}
            <Switch>

                <Route exact path="/" >
                <Home size={size} noticias={noticiasHome} sliderImgs={sliderImages} videos={videos}/>
                </Route>


                <Route exact path="/noticias">
                <News  size={size} />
                </Route>

                <Route exact path="/noticias/:tags" children={<News size={size} />}/>
                {/* <News size={size} /> */}
                {/* <h1>Test</h1>
                <h1>Test</h1> */}
                {/* <NewsSearch/>
                </Route> */}

                <Route exact path="/admin">
                {admin ? <Admin user={user}/> : null}
                </Route>

                <Route exact path="/conocenos">
                <Conocenos/>
                </Route>

                <Route path="/terminos-y-condiciones">
                <TerminosCondiciones/>
                </Route>

                <Route path="/contacto">
                <Contacto/>
                </Route>

                <Route path="/create">
                {admin ? <NewsCreator/> : null}
                </Route>

                <Route exact path="/noticia/:name" component={Noticia} />

            </Switch>
      </BrowserRouter>
    </div>

  );
}

export default App;
