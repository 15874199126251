import * as React from "react";

import Logo from "../../img/Logo.gif";
import LogoMobile from "../../img/Logomobile.gif";

import NavButton from "./navbutton.jsx";

import SearchButton from "./searchButton.jsx"
import SocialMedia from "../socialMedia.jsx";

import Grid from '@mui/material/Grid';
import MobileMenu from './mobilemenu.jsx'
import { useState } from "react";

const MobileBar = (props) =>{
    const [openModal,setOpenModal] = useState(false)
    const handleModal = () =>{
        setOpenModal(true)
    }


    return(
        <>
        <Grid item xs={2}
          style={{  display: "flex", flexWrap:'wrap', justifyContent:'center', alignItems: "center", backgroundColor:'#d8d8d8', borderRadius:'0px 35px 35px 0px' }}
        >
          <img src={LogoMobile} alt="" srcSet="" 
          style={{maxHeight:'100%', maxWidth:'90%'}}
          />

        </Grid>

        <Grid item xs={5} 
          style={{  display: "flex", alignItems: "center"}}
        >
        <SearchButton/>
        </Grid>
        <Grid item xs={2} 
          style={{  display: "flex", alignItems: "center", justifyContent: "center"}}
        >
             <MobileMenu shouldOpen={openModal} buttonsText={props.buttonsText} onAction={props.onAction}/>

        </Grid>
       
        {/* <Grid item xs={4}  lg={7}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            // width:'45vw'
          }}
        >
          {props.buttonsText.map((button, index) => {
            return <NavButton text={button} index={index} />;
          })}
        </Grid>
           
        <Grid item xs={1} lg={1} md={1} style={{ display: "flex", alignItems: "center" }}>

        <SocialMedia/>
        </Grid> */}
        </>
    )
}

export default MobileBar