import {db} from '../firebase/app'
import { collection, addDoc, Timestamp} from "firebase/firestore";




export const insertNoticia = async (data) =>{
const doc = await addDoc(collection(db, "noticias"), {
        title:data.title,
        subtitle:data.subtitle,
        text1: data.text1,
        text2:data.text2,
        videourl:data.videourl,
        img_lg: data.img_lg,
        img_sm: data.img_sm,
        date: Timestamp.fromDate(new Date()),
        showathome:false
}).catch((e)=>{
    console.error(e)
});
}
