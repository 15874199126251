import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import {useState, useEffect} from 'react'
import DesktopBar from './desktopbar.jsx'
import MobileBar from './mobilebar.jsx'
import { Link } from 'react-router';
// import useState from 'React'

export default function ButtonAppBar(props) {
  const buttonsText = [
    {link: '/', name:"HOME"},
    {link: '/noticias', name:"NOTICIAS"},
    // "SHOWS",
    // "ENTREVISTAS",
    {link: '/conocenos', name:"CONOCENOS"},
    {link: '/contacto', name:"CONTACTO"},
    // "INICIAR SESION"
  ];
  props.scrollTo()
  return (
    <Box sx={{ width: "100%", position:'fixed', top:0, zIndex: 1000}}>
      <Grid container columns={{ xs: 6,  lg: 12}}>
      {/* style={{boxShadow:'1px 19px 25px 25px rgba(221,46,96,0.74)'}} */}
      <AppBar
        position="static"
        sx={{
          bgcolor: "#dd2e60",
          display: "flex",
          height: "8vh",
          minHeight:'60px',
          flexDirection: 'row',
        }}
      >
        {props.size>850 ? <DesktopBar buttonsText={buttonsText} user={props.user} onLogout={props.onLogout}/> : <MobileBar buttonsText={buttonsText}/>}
        
        
      </AppBar>

        </Grid>

    </Box>
  );
}
