import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import Footer from '../components/footer'
const LineDiv = () =>{

    return(
        <div style={{width:"100%", height:"5px", backgroundColor:'rgb(221, 46, 96)'}}></div>

    )
}

const TerminosCondiciones = () =>{
    const styles={
        h1:{
            fontFamily:'Bebas Neue Pro Regular',
            fontSize:'2.5rem',
            color:'rgb(221, 46, 96)'
        },
        text:{
            fontFamily:'Poppins, sans-serif', 
            fontWeight:'bold',
            textAlign:'left',
            fontSize:'1.2rem'
        },
        subtitles:{
            fontFamily:'Poppins, sans-serif', 
            fontWeight:'bold',
            textAlign:'left',
            fontSize:'1.4rem',
            color:'rgb(221, 46, 96)',
            marginTop:'2rem'
        }
    }
    return(
        <>
        <Container maxWidth="lg" style={{marginTop: "10vh", padding:0}}>
        <Grid container xs={12} >
            <Grid item xs={12}>
                <h1 style={styles.h1}>TÉRMINOS Y CONDICIONES</h1>
                <p style={styles.text}>El portal www.jukeourbano.com quien en adelante se llamará “El Sitio Web”, es de propiedad de JUKEO URBANO LLC, en adelante “Jukeo Urbano”. El acceso a este sitio, su uso y participación estará determinado por los términos y condiciones que más adelante se refieren y que se consideran aceptados y conocidos por quienes accedan a este “Sitio Web” y que de ahora en adelante se llamarán “Usuarios”. 
                </p>
                <LineDiv/>
            </Grid>
        <Grid container style={{display: 'flex'}}>

            <Grid item lg={6} sm={12} padding={2}>
                <p style={styles.subtitles}>Propiedad del contenido del Sitio Web - Copyright.</p>
                <p style={styles.text}>
                Este Sitio Web y el contenido del mismo son de propiedad de JUKEO URBANO.Queda prohibido realizar reproducción, total o parcial de este,  traducción, inclusión, transmisión, almacenamiento o acceso a través de medios analógicos, digitales o de cualquier otro sistema o tecnología creada o por crearse, sin autorización previa y escrita de JUKEO URBANO. <br/>
                <br/>
                No obstante, es posible descargar material del Sitio Web para su uso personal y no comercial, siempre y cuando se haga expresa mención de la propiedad en cabeza de JUKEO URBANO. 
                </p>
                <p style={styles.subtitles}>Información solicitada </p>
                <p style={styles.text}>
                Nuestro Sitio Web podrá solicitar información personal como lo son:  Nombre, información de contacto como su dirección de correo electrónica e información demográfica.<br/>
                Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún servicio o realizar una entrega o facturación.

                </p>


            </Grid>
            <Grid item lg={6} sm={12} padding={2}>
                <p style={styles.subtitles}>Política y aviso de privacidad</p>
                <p style={styles.text}>
                La política de privacidad de JUKEO URBANO, referida al aviso de privacidad y del manual interno de tratamiento de datos personales que es aceptado previa y expresamente por los Usuarios del Sitio Web, al momento de  Registrarse, para los fines que allí se informan de manera previa, expresa e informada, será el siguiente: 
                </p>
                <p style={styles.subtitles}>Uso de información solicitada </p>
                <p style={styles.text}>
                Nuestro sitio web emplea la información para lo siguiente: 
                </p>

                <ul style={{...styles.text, margin:0, paddingLeft:'1.5rem' ,listStyleType:'disc'}}>
                    <li>Proporcionar un excelente servicio al usuario. </li>
                    <li>Registro de usuarios en la Página Web. </li>
                    <li>Para gestionar solicitudes de servicios o productos. </li>
                </ul>
               

            </Grid>
        </Grid>
        <LineDiv/>
            <Grid item xs={12}>
                </Grid>
                    <p style={styles.text}>
                    Es posible que nuestros usuarios registrados reciban correos  electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento por el Usuario en caso que no quiera recibirlos. Opción que también puede elegir en el momento de proporcionar sus datos a nuestro sitio web. <br/><br/>
                    Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con una orden judicial.
                    Jukeo Urbano se reserva el derecho de cambiar, actualizar  los términos de la presente Política de Privacidad en cualquier momento, además se compromete con la seguridad y protección de los datos de sus usuarios y su uso será expresamente con los términos internos de Política y Privacidad. Por lo tanto, asegúrese de revisar de manera constante este sitio para que tenga conocimiento de dichos cambios. <br/><br/>
                    Jukeo Urbano, no es responsable de los enlaces que conlleven a sitios de terceros, por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.
                    </p>

            </Grid>
      </Container>
      <Footer/>
      </>
    )
}

export default TerminosCondiciones