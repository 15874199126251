import NoticiasJSON from "../noticias.json";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import YoutubeSection from "../components/youtubeembed.jsx";
import Background from "../components/background.jsx";
import Footer from "../components/footer.jsx";
import {youtube_parser} from '../functions/youtubeparser'
import Tags from './tags.jsx'
import {useEffect, useState} from 'react'
const NoticiaTemplate = (props) => {
  const [actualNoticia,setActualNoticia] = useState({})
  useEffect(() => {
    setActualNoticia(props.noticia)

  },[props.noticia])
  
  useEffect(()=>{
    // console.log((actualNoticia.img_lg).toUpperCase());

  },[actualNoticia])
  var noticia = props.noticia;
  const styles = {
    banner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    bannerDiv: {
      width: "100%",
      height: "100%",
      position: "relative",
      left: "50%",
      marginLeft: "-100%",
    },
    newsText: {
      textAlign: "center",
      fontFamily: "Bebas Neue Pro Regular",
      fontSize: "1.5rem",
    },
    text: {
      fontFamily: "Poppins, sans-serif"
    },
  };

  return (
    <>
    <Grid container>

    <Container maxWidth="lg" style={{ paddingTop: "15vh" }}>
        <Grid item xs={12}>
          <div
          style={{display: "flex",justifyContent: 'center', height: "50vh"}}
          //  style={{height: "50vh", width: "100%", borderRadius: "30px", backgroundSize:'cover',backgroundPosition: '50% 20%' }}
          >
          <img src={noticia.img_lg} alt="" srcset="" style={{width:'100%', minWidth:'900px', objectFit:'cover', borderRadius: "30px"}} />

          </div>
          <h1 style={{ color: "#dd2e60", ...styles.text, fontWeight:'600', marginTop:'5vh', textAlign: "center"}}>{(noticia.title).toUpperCase()} </h1>
        </Grid>
        <Grid container>

        <Grid item md={8} style={{ textAlign: "left",marginBottom:'5vh' }}>
        <div style={{ ...styles.text, textAlign:'left', fontSize: "20px" , wordWrap: "break-word",marginTop:'5vh', marginBottom:'5vh' }}>
          {noticia.text1.split(/[\n\r]/g).map((text)=>{
            return <p>{text}</p>  
          })}
        </div>

        </Grid>
        <Grid item md={4} style={{ width: "100%", display:'flex',alignItems: 'center',justifyContent:'center'}}>
          <img
            src={noticia.img_sm}
            alt=""
            srcSet=""
            style={{
              width: "300px",
              height: "300px",
              objectFit: "cover",
              borderRadius: "30px",
              margin: "20px",
              marginBottom:'100px'
            }}
          />
        </Grid>
        </Grid>

          
    </Container>
    <Grid item xs={12} style={styles.banner}>

    <div style={{ backgroundColor: "black", ...styles.bannerDiv }}>
    <Container maxWidth="lg" >

            <h2 style={{ ...styles.text, color: "white", fontSize: "32px",textAlign:'center' }}>
              {noticia.subtitle}
            </h2>
    </Container>

            <YoutubeSection thumbs={null} videos={[youtube_parser(noticia.videourl)]} />

          </div>
          
        </Grid>
        <Container maxWidth="lg" >
        <div style={{ ...styles.text, textAlign:'left', fontSize: "20px" , wordWrap: "break-word",marginTop:'5vh', marginBottom:'5vh' }}>
          {noticia.text2.split(/[\n\r]/g).map((text)=>{
            return <p>{text}</p>  
          })}
        </div>
        <div style={{marginBottom:'5vh'}}>
          {noticia.tags !== undefined && <Tags tags={noticia.tags}/>}
        </div>
    </Container>
    </Grid>
  <Footer/>
</>
  );
};

export default NoticiaTemplate;
