import Noticia1 from '../img/noticias/noticia1.jpg'
import Noticia2 from '../img/noticias/noticia2.jpg'
import Noticia3 from '../img/noticias/noticia3.jpg'
import Noticia from "../img/Sombra-noticia-grande.gif";
import Article from './article'
import '../styles/carousel.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useState,useEffect} from 'react'
import { Carousel } from 'react-responsive-carousel';
import {Link} from 'react-router-dom'




const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 5vh)',
    cursor: 'pointer',
    fontSize:'10vh', 
    fontFamily:"Bebas Neue Pro Regular",
    fontWeight:"bold",
    color:'#dd2e60',
    backgroundColor:'transparent',
    outline:'none',
    border:'none',
    '&::hover':{
        color:'red'
    }
};
const styles ={
    itemContainer:{
        position:'relative', 
        backgroundRepeat: 'none', 
        backgroundSize:'cover', 
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width:"100%", 
        height:"50vh", 
        backgroundPosition: '50% 20%' 
    },
    itemText:{
        fontFamily:'Bebas Neue Pro Bold', 
        color:'#d8d8d8',
        letterSpacing:'4px', 
        fontSize:'4vw', 
        paddingLeft:'18%', 
        paddingRight:'18%',
        overflow:'hidden',
        textOverflow:'ellipsis',
        lineClamp:3,
        maxHeight:'15vw',
        lineHeight:'5vw'
    },
    itemTextMoble:{
        fontSize:'32px', margin:'0px',textAlign:'left', color:'#d8d8d8', fontWeight:'bold', marginTop: '15px',textAlign:'center'
    }
}
const CarouselHome = (props) =>{
    const [images,setImages] = useState(null)
    const [mobile,setMobile] = useState(false)
    useEffect(() =>{
        props.images != null  && setImages(props.images)
    },[props.images])

    useEffect(()=>{
        props.size<700 ? setMobile(true): setMobile(false)
        
    },[props.size])
    return (
        <div >

            <Carousel 
            autoPlay={true}
            showArrows={true} 
            interval={7000}
            infiniteLoop={true} 
            showThumbs={false} 
            showStatus={false}  
            centerSlidePercentage={80} 
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && !mobile && (
                    <button onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: '15%'}} >
                    {'<'}
                    </button>)
                }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && !mobile && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: '15%' }}>
                        {'>'}
                    </button>)
                }>    
                {images != null && images.map((image,index) =>{
                return <Link to={'noticia/' + image.title} style={{textDecoration: 'none',color:'black'}} key={index}>
                    <div style={{...styles.itemContainer, backgroundImage: 'linear-gradient(90deg, rgba(0,0,0,1) 15%, rgba(9,9,121,0) 52%, rgba(0,0,0,1) 85%)'}}>
                <div style={{ ...styles.itemContainer, backgroundImage: 'url('+image.img_lg +')'}}>
                    <h1 style={mobile ? styles.itemTextMoble : styles.itemText}>{image.title}</h1>
                </div>
                </div>
                </Link>
                }
                )
                }

            
                        
                
            </Carousel>
        </div>

    )
}

export default CarouselHome