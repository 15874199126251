import NoticiasJSON from '../noticias.json'
import Grid from "@mui/material/Grid";
import { Container } from '@mui/material';
import YoutubeSection from '../components/youtubeembed.jsx'
import Background from '../components/background.jsx'
import NoticiaTemplate from '../components/noticiatemplate'
import {getUniqueNoticia} from '../functions/getuniquenoticia'
import { useState,useEffect } from 'react';
import Loading from '../components/loading'

const Noticia =(props) =>{

    let idNoticia = props.match.params.name
    // console.log(idNoticia)
    // var noticia =  NoticiasJSON.find(e=>e.name==idNoticia) 
    const [noticia,setNoticia] = useState(null)

    const getNoticia = async () =>{
      let noticia = await getUniqueNoticia(idNoticia)
      setNoticia(noticia)
    }

    useEffect(() => {
      getNoticia() 
    },[])
    useEffect(() => {


    },[noticia])

    // console.log(noticia)

    // console.log(props.match.params.id)
    // console.log(NoticiasJSON)
    
  
    // console.log(noticia)
    const styles ={
        banner:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          },
          bannerDiv:{
            width: "100vw", 
            position: "relative",
            left:'50%',
            marginLeft: "-100%"
          },newsText:{
            textAlign: "center",fontFamily: "Bebas Neue Pro Regular", fontSize:'1.5rem', letterSpacing:'3px'
          },
          text: {
            fontFamily: "Bebas Neue Pro Regular"
          }

    }

    return(

    noticia!=null ? 
      <NoticiaTemplate noticia={noticia} /> : 
      <Loading/>

    )
}

export default Noticia